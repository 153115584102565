import React, { useState } from "react";
import { usePlayer, useStage, useGame, useRound, useStageTimer } from "@empirica/core/player/classic/react";
import BoardComponent from "../components/BoardComponent.jsx";
import ShipsRemainingComponent from "../components/ShipsRemainingComponent.jsx";
import { Button } from "../components/Button.jsx";
import { SpotterBoardComponent } from "../components/SpotterBoardComponent.jsx";
import { HistoryComponent } from "../components/HistoryComponent.jsx";
import { SendMessage } from "../../../utils/SendMessage.js";
import Board, { hasGameEnded } from "./Board.js";
import { NextGameButton } from "../components/NextGameButton.jsx";

export function SharedStage_1() {

    const stage = useStage();
    const round = useRound();
    const player = usePlayer();
    const game = useGame();
    const timer = useStageTimer();

    function askQuestion() {
        var inputText = document.getElementById('question').value;
        inputText = inputText.trim();
        if ((inputText.length >= 1 && inputText.length < 100) && round.get("questionsRemaining") > 0) {
            if (!stage.get("questionAsked")){
                SendMessage(inputText, "question", stage, round, game, timer);
                round.set("questionsRemaining", round.get("questionsRemaining")-1);
                stage.set("questionID",round.get("questionID"));
                stage.set("questionAsked",true);
            }
            player.stage.set("timedOut",false);
            player.stage.set("submit",true);
        }
    }

    switch (player.round.get("role")) {
        case "spotter":
            player.stage.set("timedOut",false);
            player.stage.set("submit", true);
            return (<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", margin: "30px", alignItems: "center", paddingTop:"50px"}}>
                        <ShipsRemainingComponent 
                            shipsStatus={round.get("shipsSunk")}
                        />
                    </div>
                    <SpotterBoardComponent 
                        occ_tiles={round.get("occTiles")}
                        init_tiles={round.get("trueTiles")}
                        ships={round.get("ships")}
                    />
                    <HistoryComponent
                        grid = {round.get("occTiles")[0].length}/>
                </div>
                <div style={{margin: "20px", fontSize:"1vw"}}><i>The captain is thinking of a question...</i></div>
                </div>);
        case "captain":
          return (<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}> 
            <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <div style={{display: "flex", flexDirection: "column", margin: "30px", alignItems: "center", paddingTop:"50px"}}>
                    <ShipsRemainingComponent 
                        shipsStatus={round.get("shipsSunk")}
                    />
                </div>
                <BoardComponent 
                    init_tiles={round.get("occTiles")}
                    ships={round.get("ships")}
                />
                <HistoryComponent
                    grid = {round.get("occTiles")[0].length}/>
            </div>
            <div style={{display:"flex", flexDirection:"row", alignItems: "center", fontSize:"1vw"}}>
                                    <input type="text" id="question" name="question" style={{height: "50px"}} autoComplete="off"/>
                                    <Button className="m-5" handleClick={askQuestion}>
                                        Submit Question
                                    </Button>
                                </div>
                                {game.get("categoricalAnswers") ? <p style={{margin: "10px", fontSize: "0.8vw"}}>Remember: the spotter can only answer your questions with "yes" or "no"!</p> : <div></div>}
            </div>); 
        default:
          return <div>This is the shared stage. You have no role: something's gone wrong!</div>;
      }
}

export function Radio({ selected, name, value, label, onChange }) {
    return (
      <label className="text-sm font-medium text-gray-700">
        <input
          className="mr-2 shadow-sm sm:text-sm"
          type="radio"
          name={name}
          value={value}
          checked={selected === value}
          onChange={onChange}
        />
        {label}
      </label>
    );
  }